import React from 'react';

import { AppWithStyles, appWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DrawerDialog } from '@shared/components/new-design/drawer-dialog';
import { OutlinedButton } from '@shared/components/new-design/outlined-button';

import { styles } from './verification-dialog.styles';

export interface VerificationDialogProps extends AppWithStyles<typeof styles> {
  isOpen: boolean;
  onClose: (result: boolean) => void;
}

const VerificationDialogComponent: React.FC<VerificationDialogProps> = ({
  classes,
  onClose,
  isOpen,
}) => {
  return (
    <DrawerDialog isOpen={isOpen} onClose={() => onClose(false)}>
      <div className={classes.root}>
        <div className={classes.title}>{t`Identity verification`}</div>
        <div
          className={classes.description}
        >{t`Please verify yourself to get unlimited access to transactions.`}</div>
        <div className={classes.submit}>
          <OutlinedButton onSubmit={() => onClose(true)} label={t`Start verification`} />
        </div>
      </div>
    </DrawerDialog>
  );
};

export const VerificationDialogDialog = appWithStyles(styles)(VerificationDialogComponent);
