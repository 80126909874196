import React from 'react';

import { appInject } from '@core/di/utils';
import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DI_TOKENS } from '@shared/constants/di';
import { ProductModel, ProductType } from '@shared/models/products/product-model';
import { Currency } from '@shared/models/wallets/currency';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { IRatesVM } from '@shared/types/rates-vm';
import { transformToBaseCurrency } from '@shared/utils/currency-pipe';
import classNames from 'classnames';

import LogoSvg from './img/logo.svg';
import MapSvg from './img/map.svg';

import { styles } from './wallet.styles';

export interface WalletProps extends AppWithStyles<typeof styles> {
  wallet: WalletsListModel;
  product: ProductModel;
  selected?: boolean;
}

const WalletsComponent: React.FC<WalletProps> = appObserver(
  ({ classes, wallet, product, selected }) => {
    const ratesVM = appInject<IRatesVM>(DI_TOKENS.ratesVM);
    return (
      <div
        className={classNames(classes.root, {
          [classes.gold as string]: wallet.asJson.currency === Currency.GSC,
          [classes.usd as string]: wallet.asJson.currency === Currency.USDT,
          [classes.aud as string]: wallet.asJson.currency === Currency.AUDT,
          [classes.selected as string]: selected,
        })}
      >
        <img className={classes.world} src={String(MapSvg)}></img>
        <img className={classes.logo} src={String(LogoSvg)}></img>
        <div className={classes.title}>
          {product.asJson.type === ProductType.SMART_COIN
            ? `${product.asJson.baseCurrency} ${t`account`}`
            : `${product.asJson.name} ${t`wallet`}`}
        </div>
        <div className={classes.balance}>
          {product.asJson.type === ProductType.SMART_COIN
            ? `${wallet.asJson.currency} ${minDigitsAfterDot(amountPipe(wallet.asJson.balance, 8))}`
            : `${wallet.asJson.currency} ${minDigitsAfterDot(
                amountPipe(wallet.asJson.balance, 2),
              )}`}
        </div>
        <div className={classes.smartCoinsPrice}>
          {product.asJson.type === ProductType.SMART_COIN
            ? `${transformToBaseCurrency(ratesVM.currency)} ${
                ratesVM.currency === Currency.USDT
                  ? minDigitsAfterDot(amountPipe(wallet.asJson.balanceInFiatCurrency, 2), 2)
                  : minDigitsAfterDot(amountPipe(wallet.asJson.balanceInBaseFiatCurrency, 2), 2)
              }`
            : ``}
        </div>
        <div className={classes.hint}>
          {product.asJson.type === ProductType.SMART_COIN
            ? `* 1 ${product.asJson.name} = 1 g of ${product.asJson.baseCurrency}`
            : `* 1 ${product.asJson.name} = 1 ${product.asJson.baseCurrency}`}
        </div>
      </div>
    );
  },
);

export default appWithStyles(styles)(WalletsComponent);
