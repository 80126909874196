import { appCreateStyles } from '@core/theme/utils/create-styles';
import { Theme } from '@mui/material';
import { Layout } from '@shared/utils/layout';

export function styles(theme: Theme) {
  return appCreateStyles({
    wallet: {
      width: '100%',
      height: '67px',
      borderRadius: '12px',
      background: '#1E1D36',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0px 20px',
      '&$selected': {
        border: '1px solid #7543FF',
      },
      [theme.breakpoints.up(Layout.laptop)]: {
        background: '#412D68',
      },
    },
    currency: {
      display: 'flex',
      alignItems: 'center',
    },
    currencyFlag: {
      width: 'fit-content',
      height: 'fit-content',
      paddingBottom: '6px',
    },
    currencyName: {
      marginLeft: '10px',
      color: '#FFF',
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '124%',
      letterSpacing: '0.2px',
    },
    balance: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    balanceTitle: {
      color: '#63608C',
      textAlign: 'right',
      fontSize: '13px',
      fontWeight: 400,
      letterSpacing: '0.3px',
    },
    balanceAmount: {
      color: '#FFF',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.3px',
    },
    walletDescription: {
      color: '#778',
      fontSize: '13px',
      fontWeight: 500,
      letterSpacing: '0.2px',
    },
    unactive: {
      '&$wallet': {
        background: 'rgba(255, 255, 255, 0.05)',
      },
      '& $currencyFlag': {
        opacity: 0.6,
        mixBlendMode: 'luminosity',
      },

      '& $currencyName': {
        color: '#44444E',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '22px',
        letterSpacing: '0.3px',
      },

      '& $radio': {
        visibility: 'hidden',
      },
    },
    drawer: {
      '& .MuiPaper-root': {
        height: '80%',
        background: '#161524',
      },
    },
    flex: {
      display: 'flex',
    },
    relationToBaseCurrency: {
      display: 'flex',
      alignItems: 'center',
      color: '#778',
      fontSize: '14px',
      fontWeight: 500,
      letterSpacing: '0.2px',
      margin: '0px 12px',
      [theme.breakpoints.up(Layout.laptop)]: {
        color: '#A4A8AB',
      },
    },
    radio: {
      display: 'flex',
      alignItems: 'center',
    },
    selected: {},
  });
}
