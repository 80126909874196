import React, { MutableRefObject, useEffect, useRef, useState } from 'react';

import { appInject } from '@core/di/utils';
import { appObserver } from '@core/state-management/utils';
import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { DI_TOKENS } from '@shared/constants/di';
import { ProductModel } from '@shared/models/products/product-model';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { IProductsVM } from '@shared/types/products-vm';
import classNames from 'classnames';
import ScrollContainer from 'react-indiana-drag-scroll';

import Wallet from './components/wallet/wallet';

import { styles } from './wallets-carousel.styles';

export interface WalletsProps extends AppWithStyles<typeof styles> {
  wallets: Array<WalletsListModel>;
  onSelectWallet: (wallet: WalletsListModel) => void;
}

const WalletsComponent: React.FC<WalletsProps> = appObserver(
  ({ classes, wallets, onSelectWallet }) => {
    const productsVM = appInject<IProductsVM>(DI_TOKENS.productsVM);
    const scrollRef = useRef<ScrollContainer | null>(null);
    const [activeWallet, setActiveWallet] = useState<number>(0);
    const ITEM_WIDTH = 365;

    useEffect(() => {
      changeWallet(0);
    }, []);

    const changeWallet = (index: number) => {
      setActiveWallet(index);
      if (onSelectWallet && wallets[index] && activeWallet !== index) {
        onSelectWallet(wallets[index] as WalletsListModel);
      }
    };

    const getStartWalletPosition = (walletNumber: number) => {
      const scrollDiv = scrollRef?.current?.getElement();
      if (scrollDiv) {
        return ITEM_WIDTH * walletNumber;
      }
      return 0;
    };

    const handleScroll = () => {
      const scrollDiv = scrollRef?.current?.getElement();
      if (scrollDiv) {
        changeWallet(Math.round(scrollDiv.scrollLeft / ITEM_WIDTH));
      }
    };

    const moveTo = (walletNumber: number) => {
      const scrollDiv = scrollRef?.current?.getElement();
      if (scrollDiv) {
        scrollDiv.scrollTo(getStartWalletPosition(walletNumber), 0);
      }
    };

    if (!wallets || wallets.length === 0) {
      return (
        <div className={classes.root}>
          <div className={classes.noData}>{t`No Wallets available`}</div>
        </div>
      );
    }

    return (
      <div className={classes.root}>
        <div className={classes.controls}>
          {wallets.map((wallet, index) => {
            return (
              <div
                id={`${index}`}
                className={classNames(classes.navItem, {
                  [classes.active as string]: activeWallet === index,
                })}
                onClick={() => moveTo(index)}
              ></div>
            );
          })}
        </div>
        <ScrollContainer
          ref={scrollRef as MutableRefObject<ScrollContainer | null>}
          className={classes.scroll}
          onScroll={handleScroll}
          draggingClassName={classes.dragging}
          vertical={false}
        >
          {wallets.map((wallet, index) => {
            return (
              <div className={classes.wallet}>
                <Wallet
                  wallet={wallet}
                  product={productsVM.getProductByCurrency(wallet.asJson.currency) as ProductModel}
                />
              </div>
            );
          })}
        </ScrollContainer>
      </div>
    );
  },
);

export default appWithStyles(styles)(WalletsComponent);
