import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {
      display: 'flex',
      marginBottom: '16px',
      marginTop: '16px',
    },
    item: {
      color: '#8182A5',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '21.5px',
      letterSpacing: '0.042px',
      transition: 'color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      cursor: 'pointer',
      '&$active': {
        color: '#FFF',
      },
      '& + &': {
        marginLeft: '40px',
      },
    },
    active: {},
  });
}
