import React from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import classNames from 'classnames';

import { styles } from './tabs.styles';

export interface TabsProps extends AppWithStyles<typeof styles> {
  options: Array<{ label: string; id: string }>;
  selected?: string;
  onChange?: (item: string) => void;
}

const TabsComponent: React.FC<TabsProps> = ({ classes, options, selected, onChange }) => {
  const handleChange = (id: string) => {
    onChange && onChange(id);
  };

  return (
    <div className={classes.root}>
      {options.map((item) => {
        return (
          <div
            onClick={() => handleChange(item.id)}
            className={classNames(classes.item, {
              [classes.active as string]: selected === item.id,
            })}
          >
            {item.label}
          </div>
        );
      })}
    </div>
  );
};

export default appWithStyles(styles)(TabsComponent);
