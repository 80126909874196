import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { withStyles } from '@mui/styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export const StyledToggleButtonGroup = withStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export const StyledToggleButton = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    background: 'transparent',
    fontWeight: `400 !important`,
    padding: '3px 8px !important',
    lineHeight: '12px !important',
    margin: 0,
    border: '1px solid transparent !important',
    borderRadius: 'inherit !important',
    color: `${_var(CSS_VARIABLES.textTitleColor)} !important`,
    fontSize: '9px !important',
  },
  selected: {
    backgroundColor: `${_var(CSS_VARIABLES.backgroundEmptyColor)} !important`,
    border: `1px solid ${_var(CSS_VARIABLES.textTitleColor)} !important`,
    fontWeight: `700 !important`,
  },
}))(ToggleButton);

export const StyledToggleButtonBlue = withStyles(() => ({
  root: {
    boxSizing: 'border-box',
    background: 'transparent',
    fontWeight: `400 !important`,
    padding: '3px 8px !important',
    lineHeight: '12px !important',
    margin: 0,
    border: '1px solid transparent !important',
    borderRadius: 'inherit !important',
    color: `${_var(CSS_VARIABLES.textTitleColor)} !important`,
    fontSize: '9px !important',
  },
  selected: {
    backgroundColor: `${_var(CSS_VARIABLES.backgroundEmptyColor)} !important`,
    border: `1px solid ${_var(CSS_VARIABLES.accentColor)} !important`,
    fontWeight: `700 !important`,

    '& > $label': {
      color: _var(CSS_VARIABLES.accentColor),
    },
  },
}))(ToggleButton);

export const StyledToggleButtonPurple = withStyles(() => ({
  root: {
    color: '#FFF',
    fontSize: '12px',
    fontWeight: 500,
    textTransform: 'uppercase',
    width: '60px',
    height: '34px',
  },
  selected: {
    color: '#FFF !important',
    fontSize: '13px',
    fontWeight: 600,
    textTransform: 'uppercase',
    borderRadius: '18px !important',
    background: '#5E2EE1 !important',
    width: '60px',
    height: '34px',
    flexShrink: 0,
  },
}))(ToggleButton);

export const StyledToggleButtonGroupGrey = withStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '48px',
    background: '#1E1D36',
    padding: '0px 3px',

    '& + &': {
      marginLeft: '16px',
    },
  },
  grouped: {
    margin: theme.spacing(0.5),
    border: 'none',
    '&:not(:first-child)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-child': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

export const StyledToggleButtonGrey = withStyles(() => ({
  root: {
    color: '#8182A5',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'capitalize',
    width: '60px',
    height: '39px',
    padding: '0px 3px',
    lineHeight: '21.5px',
    letterSpacing: '0.042px',
    flexGrow: 1,
  },
  selected: {
    color: '#FFF !important',
    fontSize: '14px',
    fontWeight: 600,
    textTransform: 'capitalize',
    borderRadius: '26px !important',
    background: '#444676 !important',
    width: '60px',
    height: '33px',
    flexShrink: 0,
    lineHeight: '21.5px',
    letterSpacing: '0.042px',
    flexGrow: 1,
  },
}))(ToggleButton);
