import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {
      width: '345px',
      height: '200px',
      borderRadius: '10px',
      margin: '0px 10px',
      position: 'relative',
      transition: 'all 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    },
    world: {
      position: 'absolute',
      left: '26px',
      top: '13px',
    },
    logo: {
      position: 'absolute',
      right: '20px',
      top: '20px',
    },
    gold: {
      background:
        'linear-gradient(239deg, #DEB578 8.25%, #A68451 45.05%, rgba(220, 178, 148, 0.60) 104.93%)',
    },
    usd: {
      background: 'linear-gradient(243deg, #1B193E 5.27%, #7064B8 50.69%, #BC9760 98.65%)',
    },
    aud: {
      background: 'linear-gradient(242deg, #BC9760 1.17%, #7064B8 50.83%, #1B193E 91.37%)',
    },
    title: {
      color: 'rgba(255, 255, 255, 0.90)',
      fontSize: '15px',
      fontWeight: 600,
      lineHeight: '20px',
      letterSpacing: '0.075px',
      position: 'absolute',
      left: '20px',
      top: '20px',
    },
    balance: {
      color: '#FFF',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '32px',
      letterSpacing: '0.12px',
      position: 'absolute',
      left: '20px',
      top: '48px',
    },
    smartCoinsPrice: {
      color: '#FEFEFE',
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '0.08px',
      position: 'absolute',
      left: '20px',
      top: '72px',
    },
    hint: {
      color: 'rgba(254, 254, 254, 0.70)',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '32px',
      letterSpacing: '0.07px',
      position: 'absolute',
      left: '20px',
      bottom: '13px',
    },
    selected: {
      width: '385px',
      height: '220px',
    },
  });
}
