import React, { useCallback } from 'react';

import {
  StyledToggleButtonGroup,
  StyledToggleButtonGroupGrey,
  StyledToggleButton,
  StyledToggleButtonPurple,
  StyledToggleButtonGrey,
  StyledToggleButtonBlue,
} from './toggle-button.styles';

export type ToggleButtonsSmallOption = { id: string | number; label: string };

export enum ToggleTypeEnum {
  DEFAULT = 'default',
  PURPLE = 'purple',
  GREY = 'grey',
  BLUE = 'blue',
}

export enum ToggleSizeEnum {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface ToggleButtonsSmallProps {
  selected: ToggleButtonsSmallOption['id'];
  options: ToggleButtonsSmallOption[];
  onChange: (option: string) => void;
  type?: ToggleTypeEnum;
  size?: ToggleSizeEnum;
}

export const ToggleButton: React.FC<ToggleButtonsSmallProps> = ({
  options,
  selected,
  onChange,
  type = ToggleTypeEnum.DEFAULT,
  size = ToggleSizeEnum.SMALL,
}) => {
  const handleChangeValue = useCallback(
    (event: React.MouseEvent<HTMLElement>, newValue: string) => {
      if (newValue === null) return;
      onChange(newValue);
    },
    [onChange],
  );

  let ButtonGroupComponent = StyledToggleButtonGroup;
  let ButtonComponent = StyledToggleButton;
  switch (type) {
    case ToggleTypeEnum.PURPLE:
      ButtonComponent = StyledToggleButtonPurple;
      ButtonGroupComponent = StyledToggleButtonGroup;
      break;
    case ToggleTypeEnum.GREY:
      ButtonComponent = StyledToggleButtonGrey;
      ButtonGroupComponent = StyledToggleButtonGroupGrey;
      break;
    case ToggleTypeEnum.BLUE:
      ButtonComponent = StyledToggleButtonBlue;
      ButtonGroupComponent = StyledToggleButtonGroup;
      break;
    default:
      ButtonComponent = StyledToggleButton;
      ButtonGroupComponent = StyledToggleButtonGroup;
  }

  return (
    <ButtonGroupComponent
      exclusive
      value={selected}
      onChange={handleChangeValue}
      size={size}
      aria-label="currency view options"
    >
      {options.map((option) => (
        <ButtonComponent key={option.id} value={option.id} aria-label={option.label}>
          {option.label}
        </ButtonComponent>
      ))}
    </ButtonGroupComponent>
  );
};
