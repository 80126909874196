import { appCreateStyles } from '@core/theme/utils/create-styles';
import { _var, CSS_VARIABLES } from '@shared/utils/layout';

export function styles() {
  return appCreateStyles({
    root: {
      height: '0px',
    },
    viewBlock: {
      padding: '20px 20px 20px 20px',
      color: '#fff',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '300px',
    },
    title: {
      color: _var(CSS_VARIABLES.colorPrimary100),
      textAlign: 'center',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '165%',
      letterSpacing: '0.4px',
      marginTop: '13px',
    },
    description: {
      color: 'rgba(255, 255, 255, 0.60)',
      textAlign: 'center',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '170%',
      letterSpacing: '0.3px',
      width: '315px',
      marginTop: '24px',
    },
    details: {
      marginTop: '16px',
      color: 'rgba(255, 255, 255, 0.60)',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '180%',
      letterSpacing: '0.3px',
      marginBottom: '75px',
    },
    submit: {
      position: 'absolute',
      bottom: '20px',
      left: '20px',
      width: 'calc(100% - 40px)',
    },
  });
}
