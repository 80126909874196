import { appCreateStyles } from '@core/theme/utils/create-styles';

export function styles() {
  return appCreateStyles({
    root: {
      marginTop: '16px',
      width: '100%',
      overflow: 'hidden',
      position: 'relative',
    },
    wallet: {
      height: '222px',
      width: 'fit-content',
    },
    controls: {
      position: 'absolute',
      bottom: '0px',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex',
    },
    navItem: {
      borderRadius: '50%',
      width: '6px',
      height: '6px',
      background: '#2C2B3E',
      cursor: 'pointer',
      '&:active': {
        background: '#2C2B3E',
      },
      '& + &': {
        marginLeft: '10px',
      },
    },
    active: {
      background: '#CCCDDB !important',
    },
    scroll: {
      scrollBehavior: 'smooth',
      transition: 'all 0.2s',
      display: 'flex',
      scrollPadding: '0 0 0 26px',
      scrollSnapType: 'x mandatory',
    },
    dragging: {
      //only if not touch device allow cursor drag
      '@media (hover: hover)': {
        scrollSnapType: 'block',
      },
    },
    noData: {
      height: '100px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });
}
