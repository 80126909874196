import React, { FC } from 'react';

import { appWithStyles, AppWithStyles } from '@core/theme/utils/with-styles';
import { t } from '@lingui/macro';
import { Radio } from '@mui/material';
import { ProductModel } from '@shared/models/products/product-model';
import { WalletsListModel } from '@shared/models/wallets/list-model';
import { amountPipe, minDigitsAfterDot } from '@shared/pipes';
import { transformToBaseCurrency } from '@shared/utils/currency-pipe';
import classNames from 'classnames';

import AUDIcon from './img/aud.svg';
import EURIcon from './img/eur.svg';
import GBPIcon from './img/gbp.svg';
import GoldIcon from './img/gold.svg';
import USDIcon from './img/usd.svg';

import { styles } from './wallet-info.styles';

export interface WalletsDialogProps extends AppWithStyles<typeof styles> {
  product: ProductModel;
  wallet: WalletsListModel;
  onSelectWallet?: (id: string) => void;
  unactive?: boolean;
  hasBalance?: boolean;
  hasRadioButton?: boolean;
  isActiveRadioButton?: boolean;
  isRelationToBaseCurrency?: boolean;
}

const WalletsInfoComponent: FC<WalletsDialogProps> = ({
  classes,
  product,
  wallet,
  unactive,
  hasRadioButton,
  hasBalance,
  isActiveRadioButton,
  isRelationToBaseCurrency,
}) => {
  const getIconByBaseCurrency = (product: ProductModel) => {
    switch (product.asJson.baseCurrency.toLowerCase()) {
      case 'gold':
        return GoldIcon;
      case 'usd':
        return USDIcon;
      case 'aud':
        return AUDIcon;
      case 'gbp':
        return GBPIcon;
      case 'eur':
        return EURIcon;
      default:
        return null;
    }
  };

  if (!product) return <></>;
  const icon = getIconByBaseCurrency(product);
  return (
    <div
      className={classNames(classes.wallet, {
        [classes.unactive]: unactive,
        [classes.selected]: isActiveRadioButton,
      })}
    >
      <div className={classes.currency}>
        <div className={classes.currencyFlag}>{icon ? <img src={String(icon)} /> : <></>}</div>
        <div className={classes.currencyName}>{transformToBaseCurrency(product.asJson.name)}</div>
      </div>
      <div className={classes.flex}>
        {isRelationToBaseCurrency ? (
          <div
            className={classes.relationToBaseCurrency}
          >{`1 ${transformToBaseCurrency(product.asJson.name)} = 1 ${product.asJson.name}`}</div>
        ) : (
          <></>
        )}
        {hasBalance ? (
          <div className={classes.balance}>
            <div className={classes.balanceTitle}>{t`Balance`}</div>
            <div className={classes.balanceAmount}>
              {minDigitsAfterDot(amountPipe(wallet.asJson.balance))}
            </div>
          </div>
        ) : (
          <></>
        )}
        {hasRadioButton ? (
          <div className={classes.radio}>
            <Radio checked={isActiveRadioButton} />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
export default appWithStyles(styles)(WalletsInfoComponent);
